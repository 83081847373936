<template>


        <div class="downPayment">

            <b-input-group :size="'sm'" class="mb-3">
                <template #prepend>


                    <b-input-group-text v-if="allowAmount">Cantidad</b-input-group-text>
                    <b-input-group-text v-if="allowPercent">Porcentaje</b-input-group-text>
                    

                    <b-dropdown size="sm" :text="dropDownText" variant="heavenly" v-if="mode=='both'">
                        <b-dropdown-item @click="switchAmount">Cantidad</b-dropdown-item>
                        <b-dropdown-item @click="switchPercent">Porcentaje</b-dropdown-item>
                    </b-dropdown>
                </template>
                <template v-if="isAmount">
                    <b-form-input class="control-heavenly" v-bind:disabled="disabled" v-number-format v-model="amount_value" @input="onChange"></b-form-input>
                </template>
                <template v-if="isPercent">
                    <b-form-input variant="success" v-bind:disabled="disabled" class="styledrange" @input="onChange" v-model="progress_value" type="range" :min="0" :max="100" step="1"></b-form-input>
                </template>
                
                <template #append>
                    <b-input-group-text v-text="typeText"></b-input-group-text>
                </template>
            </b-input-group>

        </div>

        
</template>

<script>
    


    export default{

        name:"downPayment",
        data(){

            return {
                progress_value:10,
                amount_value:0,
                selected_mode:'percent',
                
            }

        },

        props:{

                initial_value:{
                    type:Number,
                    required:false,
                    default:10
                    
                },

                mode:{
                    type:String,
                    required:false,
                    default:'both'


                },
                default_mode:{
                    type:String,
                    required:false,
                },
                disabled:{
                    default:false
                }

        },        
        computed:{


            allowPercent(){

                return this.mode=='percent';

            },
            allowAmount(){
                return this.mode=='amount';
            },

            dropDownText(){

                return this.selected_mode=='percent'?'Porcentaje':'Cantidad';

            },
            isPercent(){
            

                return this.mode=='percent' || this.selected_mode=='percent';

            },
            isAmount(){
                return this.mode=='amount' || this.selected_mode=='amount';

            },
            typeText(){

                return this.mode=='percent'?this.progress_value+' %':'.00';

            }
        

        },
        methods:{


            setModes(){

                if(this.mode=='both'){

                    if(!this.default_mode){
                        this.selected_mode='percent';
                    }else{
                        this.selected_mode=this.default_mode;
                    }


                    }else{
                    if(!this.default_mode){
                        this.selected_mode=this.mode;
                    }else{
                        this.selected_mode=this.default_mode;
                    }

                    console.log(":DDDD",this.selected_mode);

                    }



            },


            onChange(){


                this.triggerInput();


            },

            addPayment(){

                this.subsecuent_payments.push({title:'',month:'',percent:10});


            },


            switchPercent(){
                this.selected_mode='percent';
            },
        
            
            switchAmount(){
                this.selected_mode='amount';
            },


            triggerInput(){
                this.$emit('input',{amount:this.$float(this.amount_value),percent:this.$int(this.progress_value),type:this.selected_mode});

            },

            setInitialValue(v){

                if(this.selected_mode=='percent'){
                    this.progress_value=v;
                }else{
                    this.amount_value=v;
                }

            }


        },

        watch:{



            progress_value:function(v){

                this.$emit('onValue',v);
                this.triggerInput();
                
            },
            amount_value:function(v){
                
                this.$emit('onValue',v);
                this.triggerInput();
            },
            mode:function(){

                this.setModes();
            },
            
            selected_mode:function(){
                
                this.$emit('onType',this.selected_mode);

            },
            initial_value:function(v){

                this.setInitialValue(v);
                    
            }




        },


        mounted(){

            
            this.triggerInput();

            this.setInitialValue(this.$props.initial_value);

        }



    }

</script>
