<!---Author: Jairo Chi Mendoza--->
<template>

        <div class="installments">



                <div class="form-group">


                    <div class="table-responsive">

                        <table class="table">

                            <thead>
                                <th>No. de pago</th>
                                <th>Fecha</th>
                                <th>Cantidad</th>
                                <th></th>
                            </thead>
                            <tbody>

                                <tr v-for="(item,index) in getItems" :key="index">
                                    <td>
                                        {{ index+1 }}
                                    </td>
                                    <td>{{$customDate(item.due_date)}}</td>
                                    <td><b-form-input class="input-heavenly" v-bind:disabled="item.disabled" v-number-format v-model="item.amount" @input="calculatePayments(index)"></b-form-input></td>
                                    <td>
                                        <div class="option-edit" v-if="item.disabled && item.completed!=1 && amortization_type=='custom'">
                                            <a class="action-link" v-if="isSaved(index)" @click="restoreItem(index)"><i class="mdi mdi-restore"></i></a>
                                            <a class="action-link" @click="editItem(index)"><i class="mdi mdi-square-edit-outline"></i></a>
                                        </div>
                                        <div class="option-done" v-if="!item.disabled && item.completed!=1">
                                            <a class="action-link" @click="saveItem(index)" ><i class="mdi mdi-check"></i></a>
                                            <a class="action-link" @click="cancelEdition(index)"><i class="mdi mdi-cancel"></i></a>
                                        </div>

                                    </td>


                                </tr>

                            </tbody>



                        </table>


                    </div>

            
                </div>


                
                        


        </div>

        
</template>

<script>
    




    export default{

        name:"installments",
        components:{

        },
        data(){

            return {
                items:[],
                max_percent:0,
                previousValue:0,
                fixedItems:[],
                binded:false,
                payments_sum:0,
                installments:[]

                
            
            }

        },

        props:{
                balance:{
                    type:Number,
                    required:true                    
                },
                now:{
                    type:String,
                    
                },

                counter:{
                    type:Number,
                    required:true
                },
                payments:{
                    type:Array,
                    required:false,
                },
                amortization_type:{
                    type:String,
                    required:false,
                    default:'custom'

                },
                annual_interest:{
                    
                    required:false

                }



        },        
        computed:{

            calculateAverageFee(){


                return function(index){
                    
                
                    let current_value=0;
                    let counter_less=0;

                    //Obtener el total de pagos generados
                    const total_items=this.counter;
                    //contar los montos fijados
                    const total_saved=this.fixedItems.length;
                    //contar los pagos completados, estos no se pueden mover ni modificar
                    const total_completed=this.countCompletedItems;

                    //los pagos afectados, son el total restando los fijados y los completados                    
                    let affected=total_items-total_saved-total_completed;


                    //si la cuota promedio se está calculando mientras se escribe en un campo,
                    //Afectar tanto la cantidad como el número de pagos al promediar
                    if(index!=undefined){
                        current_value=this.$float(this.items[index].amount);
                        counter_less=1;
                    }
                    
            
                    
                    //Calcular el pago promedio, obteniendo el saldo de la la propiedad :balance del componente, menos cantidades fijadas menos el valor del campo actual si aplica
                    //menos los pagos completados
                    const avg_fee=(this.getBalance-this.sumCompleted()-this.sumFixed()-current_value)/(affected-counter_less);

                    
                    return avg_fee;
                }
                    
                    
            },


            countCompletedItems(){

                return this.installments.reduce(function(total,el){
                    if(el.completed==1){
                        total++;
                    }
                    return total;
                },0);

            },


            getBalance(){
                return this.balance;
            },
                


            isSaved(){

                return function(index){

                    return this.fixedItems.find((item)=>item.index==index);

                }

            },
                
                
            getItems(){
                
                    return this.items;
            },

            getItemDate(){



                return function(index){
                    
                    return this.$addMonths(this.now,index);
                }
            },


            




        },
        methods:{


            generatePaymentItems(){


                if(this.amortization_type=='french'){

                    this.generateFrenchPayments();
                    
                }else{
                    this.generateCustomPayments();
                }


            },


            calculateFrenchFee(amount,yearRate,n){

                const monthlyRate = yearRate / 12 / 100;


                let fee = (amount * monthlyRate) / (1 - Math.pow(1 + monthlyRate, -n));


                if(yearRate=='' || yearRate<=0){
                    fee=amount/n;
                }


                console.log(fee);

                return fee;

            },

            generateFrenchPayments(){


                

                const fee=this.calculateFrenchFee(this.balance,this.annual_interest,this.counter);


                let balance=this.balance;
                const rate= this.annual_interest / 12 / 100;


                this.clearItems();

                this.clearFixedItems();


                for(let i=0;i<this.counter;i++){


                    const interest = balance * rate;
                    const principal = fee - interest;

                    let item={amount:fee,payment_number:i,completed:0,fixed:false,principal:principal,interest:interest};
                    this.addPaymentItem(item);
                }




            },


            generateCustomPayments(){


                const counter=this.counter;
                
                this.clearFixedItems();
                //Calcular la cuota promedio
                const avg_fee=this.calculateAverageFee();
                let fixed=0;
                let amount;
                let completed=0;
                
                this.items=[];

                //Cada vez que se modifica el valor del contador se generan todos los pagos de nuevo
                //esto porque en el componente contador, se puede escribir directamente la cantidad de pagos en lugar
                //de usar las flechas de incremento o decremento en 1
                
                

                for(let i=0;i<counter;i++){


                    //el monto es la cuota promedio por defecto
                    amount=avg_fee;

                    //Si el elemento tiene un monto fijado, entonces el monto es el del elemento fijado
                    fixed=0;
                    const fixed_index=this.fixedItems.findIndex(e=>{return e.index==i});
                    if(fixed_index!=-1){
                        amount=this.fixedItems[fixed_index].item.amount;
                        fixed=1;
                    }

                    completed=0;
                    if(this.installments[i]!=undefined && this.installments[i].completed==1){
                        completed=1;
                        amount=this.installments[i].amount;
                        
                    }

                    let item={amount:amount,payment_number:i,completed:completed,fixed:fixed};

                
                    this.addPaymentItem(item);
                }


            },


            clearFixedItems(){

                for(let i in this.fixedItems){
                    if(this.fixedItems[i].index>this.counter-1){
                        this.fixedItems.splice(i,1);
                    }
                }

            },

            clearItems(){

                this.items=[];

            },

            calculatePayments(index){

                

                let avg_fee=this.calculateAverageFee(index);

                


                
                for(let i in this.items){

                    let item=this.items[i];

                    let ignore=this.fixedItems.find(function(it){
                        return it.index==i;
                    });

                    

                    if(i!=index && !ignore && item.completed!=1){
                        
                        item.amount=this.$numberFormat(avg_fee);

                    }
                
                }
                    


            },


            onDate(event,index){


                    let m=this.$addMonths(this.now,event);
                    this.items[index].due_date=m;


             },


            editItem(index){
                
                const item=this.items[index];
                this.previousValue=item.amount;
                item.disabled=false;
    
    
            },
            saveItem(index){
    
                const item=this.items[index];
                this.items[index].fixed=1;
                this.fixedItems.push({index:index,item:item});
                item.disabled=true;
    
    
            },

            cancelEdition(index){
    
                const item=this.items[index];
                item.amount=this.previousValue;
                item.disabled=true;

    
    
            },


            restoreItem(index){


                    

                    
                    const foundIndex = this.fixedItems.findIndex(function(e) {
                        return e.index == index;
                    });

                    if (foundIndex !== -1) {
                        this.fixedItems.splice(foundIndex, 1);
                    }


                    this.generatePaymentItems();


                

            },



            updateDates(){

                for(let i in this.items){
                    const new_date=this.$addMonths(this.now,parseInt(i));
                    this.items[i].due_date=new_date;
                }


             },

             sumCompleted(){
                
                let payment_sum=0;
                for(let i=0;i<this.installments.length;i++){
                    const it=this.installments[i];
                    if(it.completed==1){
                        payment_sum+=this.$float(it.amount);
                    }

                }
                return payment_sum;                
             },

             sumFixed(){
                let saved_sum=0;
                    for(let i=0;i<this.fixedItems.length;i++){
                        saved_sum+=this.$float(this.fixedItems[i].item.amount);
                    }

                return saved_sum;

             },

             addPaymentItem(item){            
                this.items.push(this.makePaymentItem(item));
             },

             makePaymentItem(item){

                const disabled=item.hasOwnProperty('disabled')?item.disabled:true;
                
                return {completed:item.completed,
                                 payment_number:item.payment_number,
                                 fixed:item.fixed,
                                 payment_type:'installment',
                                 due_date:this.getItemDate(item.payment_number),
                                 disabled:disabled,
                                 amount:this.$numberFormat(item.amount),
                                 max_amount:0,
                                 max_percent:this.max_percent};


             },

             setInitialFixedItems(){

                const installments=this.installments;
                for(let i in installments){
                    let it=installments[i];
                    if(it.fixed==1){
                        this.fixedItems.push({index:i,item:this.makePaymentItem(it)});
                    }
                }

                

             },
             setInstallments(){
                for(let i in this.payments){
                    let item=this.payments[i];
                    if(item.payment_type=='installment'){
                        this.installments.push(item);
                    }

                }
             }



        },

        
        mounted(){

            //La función encargada de generar todos los objetos de pagos es generatePaymentItems
            //La función encargada de reajustar las cantidades en cada pago es calculatePayments
            this.setInstallments();
            this.setInitialFixedItems();
            this.generatePaymentItems();
            
            
            
        },

        watch:{

            
            counter:function(){
                this.generatePaymentItems();
            },
            balance:function(){
                this.generatePaymentItems();
            },
            amortization_type:function(){

                this.generatePaymentItems();

            },
            annual_interest:function(){

                this.generatePaymentItems();


            },

            now:function(){

                this.updateDates();


            },
            items:{
                
                handler(items){
                    if(items.length>0)
                        {this.$emit('change',items);
                    }
                
                },
                deep:true               
        
             }




        }

    }

</script>
