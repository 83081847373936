<template>


            <section>

                <div class="overflowh">
                <a href="javascript:void(0)" v-if="showLock" @click="switchEditable(true)" class="float-right action-link"><i class="mdi mdi-lock-outline"></i></a>
                <a href="javascript:void(0)" v-if="showUnlock" @click="switchEditable(false)" class="float-right action-link"><i class="mdi mdi-lock-open-outline"></i></a>
                </div>

            <form ref="formInnerClient" @submit.prevent="save">

                
                <div class="row">

                    <div class="col-md-3 col-sm-3 col-lg-3">
                        <div class="form-group">
                            <label for="name">Nombre *</label>
                        
                            <a class="cleaner" v-if="!editable" href="javascript:void(0)" @click="cleanInputs"><i class="mdi mdi-close-circle-outline"></i></a>
                            <searchInput :key="ajax_cleaner" v-bind:disabled="!editable" @value="onSearchValue" v-if="isModelReady" :ajax="'/clients'" @onselect="onClientSelect" v-bind:required="'required'" v-model="model.id" v-bind:defaultLabel="model.name" v-bind:defaultValue="model.id"/>
                            <input v-else type="text" v-model="model.name" required name="name" class="form-control form-control-lg" placeholder="Nombre del cliente">
                            
                        
                        </div>                        
                    </div>

                    <div class="col-md-3 col-sm-3 col-lg-3">
                        <div class="form-group">
                                <label for="name">INE *</label>
                                <input type="text" v-model="model.identification" v-bind:disabled="!editable" required name="identification" class="form-control form-control-lg" placeholder="Identificación">
                                
                        </div>    
                    </div>

                    <div class="col-md-3 col-sm-3 col-lg-3">
                        <div class="form-group">
                            <label for="name">Correo *</label>
                            <input type="text" v-model="model.email" v-bind:disabled="!editable" required name="email" class="form-control form-control-lg" placeholder="Correo electrónico">
                        </div>                        
                    </div>


                    <div class="col-md-3 col-sm-3 col-lg-3">
                        <div class="form-group">
                            <label for="name">Móvil *</label>
                            <input type="text" v-bind:disabled="!editable" v-model="model.mobile" required name="mobile" class="form-control form-control-lg" placeholder="Número de teléfono móvil">
                        </div>                        
                        
                    </div>
                </div>




                <div class="row">


                    <div class="col-md-3 col-sm-3 col-lg-3">
                        <div class="form-group">
                            <label for="name">País *</label>
                            <searchInput :name="'country'" required :key="cleaner" @onselect="onCountrySelection" v-bind:disabled="!editable" :defaultValue="model.country" :options="countryList"/>
                        </div>                        
                    </div>
                    <div class="col-md-3 col-sm-3 col-lg-3">
                        <div class="form-group">
                            <label for="name">Estado *</label>
                            <searchInput :name="'state'" required :key="cleaner" @onselect="onStateSelection" v-bind:disabled="!editable" :defaultValue="model.state" :options="filteredStates"/>
                            
                        </div>                        
                    </div>

                    <div class="col-md-3 col-sm-3 col-lg-3">
                        <div class="form-group">
                            <label for="name">Ciudad *</label>
                            <input name="city" required class="form-control form-control-lg" v-bind:disabled="!editable" v-model="model.city" placeholder="Escribe la ciudad"/>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-lg-3">
                        <div class="form-group">
                            <label for="name">Código postal *</label>
                            <input name="postal_code" required class="form-control form-control-lg" v-bind:disabled="!editable" v-model="model.postal_code" placeholder="Escribe el código postal"/>
                        </div>
                    </div>


                </div>


                <div class="form-group">
                    <label for="name">Dirección *</label>
                    <textarea v-model="model.address" v-bind:disabled="!editable" required id="name" rows="5" class="form-control form-control-lg" placeholder="Dirección"></textarea>
                </div>



                <div class="row">
                    <div class="col-md-6 col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label for="name">Fecha de nacimiento *</label>
                            
                          <Datepicker :position="'top'" :disabled="!editable" :inputClass="'form-control form-control-lg'" lang="es" v-model="model.birthday" placeholder="Selecciona la fecha"/>
                        </div>                  
                              
                        
                    </div>

                    <div class="col-md-6 col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label for="name">Teléfono fijo</label>
                            <input type="text" v-model="model.phone" v-bind:disabled="!editable" id="phone" class="form-control form-control-lg" placeholder="Número de teléfono fijo">
                        </div>                        
                        
                    </div>

                </div>


                <p><small>* Campos requeridos</small></p>
                

                <div class="form-group" v-if="showSubmit">
                    <submitbutton :label="isNewRecord?'Crear':'Actualizar'" :loading_flag="loading"/>
                </div>
            </form>


            </section>

</template>
<script>
import submitbutton from '@/components/ui-feature/submit-button';
import api from '@/util/api.js';
import {countryList,stateList} from '../../util/countries';
import searchInput from '@/components/searchInput';

export default {
    routeName:'clients',
    name: 'formClient',
    components:{
        
        submitbutton,
        searchInput,
        
    },

    computed:{


        isModelReady(){
            
            return this.$props.showSearch && (this.isNewRecord || (this.model.id!=undefined && this.model.id));
        },  



        showLock(){

            return this.model.id && !this.editable
            
        },
        
        showUnlock(){
            return this.model.id && this.editable
            
        },

        countryList(){

            return countryList;

        },
        stateList(){

            return stateList;

        },

        filteredStates(){

           return this.states;
        },
        getDefaultLabel(){

            return this.model.name;

        },
        getDefaultValue(){

            return parseInt(this.model.id);
        }

    },
    props:{

        id:{
            type:Number,
            required:false,
        },
        showSubmit:{
            type:Boolean,
            required:false,
            default:true

        },
        modelobject:{
           type:Object,
           required:false,
           
        },
        showSearch:{
            type:Boolean,
            required:false,
            default:false
        }


    },  
    data(){

        return {
            
            model:{
                id:0,
                name:'',
                state:0,
                country:0,
                phone:'',
                email:'',
                mobile:'',
                address:'',
                birthday:new Date(),
                postal_code:'',
                identification:'',
                city:''



            },
            
            pic:'',
            loading:false,
            states:[],
            editable:true,
            cleaner:0,
            ajax_cleaner:0,
            

        }

    },

    methods:{


        switchEditable(e){

            this.editable=e;

        },


        cleanInputs(){

          //  const obj = Object.assign({}, this.model);
          //  this.model=obj;
          for (let prop in this.model) {
            delete this.model[prop];
           }

           this.clearFormErrors(this.$refs.formInnerClient);

            this.editable=true;
            this.cleaner++;

        },
        onSearchValue(v){

            this.model.name=v;

        },

        onClientSelect(e){

            if(e.id!=undefined){

                this.model=e;            
                this.editable=false;
                this.$emit('model',this.model);
            }
        },



        onContext(ctx){

            console.log(ctx);

        },

        onStateSelection(e){
            this.model.state=e.id;
        }
        ,
        onCountrySelection(e){
            this.model.country=e.id;
            this.states=this.stateList.filter(el=>el.id_country==e.id);
        },

        selectFile(event) {
         this.model.icon = event.target.files[0];
        },

        save(){

           
            
            this.model.birthday=this.$dateToString(this.model.birthday);
                        
            
            const data = this.createFormData(this.model);

            this.loading=true;

            api.request({
                url:this.me(this.isNewRecord?'/':this.model.id),
                data,
                headers:{'Content-type':'form/data'},
                method:this.isNewRecord?'POST':'PATCH',
                }).then(response=>{

                    this.$notify({message:"Elemento " +(this.isNewRecord?"creado":"actualizado"),type:"success"});
                    this.$emit('afterSave',response);
                    
                    
                }).catch(error=>{
                    
                    this.$notify({message:"Ocurrió un error",type:"error"});
                    console.log(error);

                }).finally(()=>{

                        this.loading=false;
                });
            
        }




    },


    watch:{

        model: {
                handler(newValue) {

                    this.$emit('model',newValue);
                },
                deep: true
            }
    },

    
    
    mounted(){

    
        if(this.modelobject && this.modelobject.id){
            
            this.editable=false;
            this.model=this.modelobject;

        }else if(!this.isNewRecord){

            
                api.get(this.me(this.$props.id))
                .then(response=>{
                    
                        const data=response.data;


                        for (const key in data) {
                            if (this.model.hasOwnProperty(key)) {
                                this.model[key] = data[key];
                            }
                        }
                        
                        
                        

                }).catch(error=>{
                    console.log(error);
                });

        }
    



    },



}

</script>

<style lang="scss" scoped>

.overflowh{overflow:hidden;}
.cleaner{
    position: absolute;
    right: 26px;
    top: 31px;
    z-index: 1;
    color: #cd6ca1;
}

</style>