<template>
<b-input-group :size="'sm'" class="mb-3">
    <template #prepend>
        <b-input-group-text>Plazo</b-input-group-text>
    </template>
    <b-input-group-text class="xsmall-width">
    <VueNumericInput v-bind:disabled="disabled" :value="value" :controlsType="'updown'" :min="min" @input="onInput"/>
    </b-input-group-text>
    <template #append>
    <b-input-group-text>meses</b-input-group-text>
    </template>
</b-input-group>

</template>


<script>

import VueNumericInput from 'vue-numeric-input';

export default{

    name:'numberCounter',
    components:{
        VueNumericInput
    },

    props:{

        min:{
            type:Number,
            required:false,
            default:0
        },
        value:{
            type:Number,
            required:false,
            default:0
        },
        disabled:{
            type:Boolean,
            required:false,
            default:false

        }

    },

    methods:{

        onInput(v){
            this.$emit('input',v);
        }

    }


}

</script>